
import { Options, prop, Vue } from "vue-class-component";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import formatPriceIDR from "@/app/infrastructures/misc/common-library/FormatPriceIDR";
import validateInputEmail from "@/app/infrastructures/misc/common-library/ValidateInputEmail";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import InvoiceAttachment from "@/app/ui/views/shipment/print-receipt/modules/invoice-attachment";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { EmailController } from "@/app/ui/controllers/EmailController";

class Props {
  modelValue = prop<boolean>({
    type: Boolean,
    default: false
  });
  totalAmount = prop<number>({
    type: Number,
    default: 0
  });
  isDiscount = prop<boolean>({
    type: Boolean,
    default: false
  });
  totalAmountAfterDiscount = prop<number>({
    type: Number,
    default: 0
  });
  listData = prop<any>({
    type: Array,
    default: []
  });
}

@Options({
  emits: ["update:modelValue", "success"]
})
export default class ModalSendEmail extends Vue.with(Props) {
  toggleModalChangeEmail(bool: boolean) {
    this.$emit("update:modelValue", bool);
  }

  async submitSendEmail() {
    if (!this.isFormValid) {
      return;
    }

    MainAppController.showLoading();
    const emailPrintReceiptAttachment = await this.generateFileAttachment();
    const resp = await EmailController.sendPrintReceiptEmail({
      totalReceipt: this.listDataAttachment.length,
      totalBill: this.totalAmountAfterDiscount,
      emailRecipient: this.email,
      attachment: emailPrintReceiptAttachment
    });

    if (resp) {
      this.openSnackbar();
      this.$emit("success");
      this.toggleModalChangeEmail(false);
    }
    MainAppController.closeLoading();
  }

  // snackbar success send email
  openSnackbar() {
    MainAppController.setSnackbarMessage("Kirim email berhasil !");
    MainAppController.setSnackbarTimeout(5000);
    MainAppController.setSnackbar(true);
  }

  // new email
  email = "";
  get errorInputEmail() {
    return !validateInputEmail(this.email);
  }
  get isFormValid() {
    return this.email && !this.errorInputEmail;
  }

  // attachment
  get dataProfile() {
    return AccountController.accountData;
  }
  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }
  get createdAt() {
    return formatDate(new Date());
  }
  get listDataAttachment() {
    return this.listData.map((item: any, index: number) => ({
      no: index + 1,
      sttNo: item.sttNo,
      sttDestination: ellipsisString(
        `${item.sttDestinationDistrictName}, ${item.sttDestinationCity} - ${item.sttDestinationCityCode}`,
        40
      ),
      sttRecipientPhone: item.sttRecipientPhone,
      sttSenderName: ellipsisString(item.sttSenderName, 20),
      sttRecipientName: ellipsisString(item.sttRecipientName, 20),
      sttChargeableWeight: item.sttChargeableWeight + " Kg",
      sttTotalAmount: formatPriceIDR(item.sttTotalAmountBeforeDiscount),
      sttIsPromo: item.sttIsPromo,
      sttTotalAmountAfterDiscount: item.sttIsPromo
        ? formatPriceIDR(item.sttTotalAmountAfterDiscount)
        : "",
      sttTotalPiece: item.sttTotalPiece
    }));
  }
  async generateFileAttachment() {
    const roleAccount = this.roleAccount
    const logo = await import(
      `@/app/ui/assets/images/logo-${roleAccount}.png`
    );
    return InvoiceAttachment({
      name:
        this.roleAccount === "internal"
          ? "Internal"
          : this.dataProfile.account_type_detail.company_name,
      isDiscount: this.isDiscount,
      total: formatPriceIDR(this.totalAmount),
      totalAfterDiscount: formatPriceIDR(this.totalAmountAfterDiscount),
      createdAt: this.createdAt,
      createdBy: this.dataProfile.name,
      dataList: this.listDataAttachment,
      logo: logo.default
    });
  }
}
