import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  style: {"width":"31rem"},
  class: "relative overflow-y-auto py-4"
}
const _hoisted_2 = { class: "flex gap-x-4 justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lp_input = _resolveComponent("lp-input")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    "model-value": _ctx.modelValue,
    persistent: true,
    title: "Kirim Tagihan",
    "onUpdate:modelValue": _ctx.toggleModalChangeEmail
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_DataWrapper, {
          label: "Alamat Email",
          asterisk: true,
          class: "mb-12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_lp_input, {
              modelValue: _ctx.email,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.email = $event)),
              placeholder: "Masukkan email Anda untuk mengirim tagihan disini",
              type: "email",
              error: _ctx.errorInputEmail,
              errorCaption: "Masukan Email dengan benar",
              "error-icon": false,
              onEnter: _ctx.submitSendEmail
            }, null, 8, ["modelValue", "error", "onEnter"])
          ]),
          _: 1
        }),
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_lp_button, {
            outline: "",
            "text-color": "red-lp-100",
            "custom-class": "px-10",
            title: "Batal",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleModalChangeEmail(false)))
          }),
          _createVNode(_component_lp_button, {
            disabled: !_ctx.isFormValid,
            "text-color": "white",
            "custom-class": "px-10",
            title: "Kirim",
            onClick: _ctx.submitSendEmail
          }, null, 8, ["disabled", "onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["model-value", "onUpdate:modelValue"]))
}