import { container } from "tsyringe";
import { VuexModule, Module, Action, getModule } from "vuex-module-decorators";
import store from "@/store";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { EmailPresenter } from "@/app/ui/presenters/EmailPresenter";
import { EmailPrintReceiptRequest } from "@/data/payload/api/EmailRequest";

@Module({ namespaced: true, dynamic: true, store, name: "email" })
class EmailStore extends VuexModule {
  @Action
  public async sendPrintReceiptEmail(params: {
    totalReceipt: number;
    totalBill: number;
    attachment: any;
    emailRecipient: string;
  }) {
    const presenter = container.resolve(EmailPresenter);
    return presenter
      .sendPrintReceiptEmail(new EmailPrintReceiptRequest(params))
      .then(() => {
        return true;
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Pengiriman Email Gagal")
        );
        return false;
      });
  }
}

export const EmailController = getModule(EmailStore);
