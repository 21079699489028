/* eslint-disable @typescript-eslint/camelcase */
import { EmailRequestInterface } from "@/data/payload/contracts/EmailRequest";

export class EmailPrintReceiptRequest implements EmailRequestInterface {
  totalReceipt = 0;
  totalBill = 0;
  attachment: any = null;
  emailRecipient = "";
  constructor(fields?: Partial<EmailPrintReceiptRequest>) {
    Object.assign(this, fields);
  }

  toFormData(): FormData {
    const formData = new FormData();
    formData.append("email_recipient", this.emailRecipient);
    formData.append("total_receipt", this.totalReceipt.toString());
    formData.append("total_bill", this.totalBill.toString());
    formData.append("is_with_attachment", "true");
    formData.append("attachment", this.attachment, "receipt.pdf");
    return formData;
  }
}
