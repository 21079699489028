import JsPdf from "jspdf";
import "jspdf-autotable";

const drawCellData = (doc: any, data: any) => {
  if (data.row.index < 0) {
    return;
  }
  if (data.column.dataKey === "sttTotalAmount" && data.row.section === "body") {
    if (data.row.raw.sttIsPromo) {
      const totalAmountWidth = doc.getTextWidth(data.row.raw.sttTotalAmount);

      data.row.cells["sttTotalAmount"].text
        .filter((item: any) => item !== "")
        .map((_: any, index: number) => {
          doc.setDrawColor("#9e9e9e");
          doc.setLineWidth(0.2);
          doc.line(
            data.row.cells["sttTotalAmount"].x + totalAmountWidth + 2,
            data.row.cells["sttTotalAmount"].y +
              3.5 +
              data.row.cells["sttTotalAmount"].contentHeight /
                (1.6333 +
                  1.75 *
                    data.row.cells["sttChargeableWeight"].text.filter(
                      (item: any) => item !== ""
                    ).length) +
              (0.0083 + 5.675 * index),
            data.row.cells["sttTotalAmount"].x + 2,
            data.row.cells["sttTotalAmount"].y +
              3.5 +
              data.row.cells["sttTotalAmount"].contentHeight /
                (1.6333 +
                  1.75 *
                    data.row.cells["sttChargeableWeight"].text.filter(
                      (item: any) => item !== ""
                    ).length) +
              (0.0083 + 5.675 * index)
          );
        });
      doc.setTextColor("#9e9e9e");
    } else {
      doc.setTextColor("#B82025");
    }
  }

  if (
    data.column.dataKey === "sttTotalAmountAfterDiscount" &&
    data.row.section === "body"
  ) {
    doc.setTextColor("#B82025");
  }
};

const table = async (
  doc: any,
  dataList: any,
  isDiscount: boolean,
  y: number
) => {
  const mainColumns = [
    { header: "No", dataKey: "no" },
    { header: "No. STT", dataKey: "sttNo" },
    { header: "Tujuan", dataKey: "sttDestination" },
    { header: "Pengirim", dataKey: "sttSenderName" },
    { header: "Penerima", dataKey: "sttRecipientName" },
    { header: "No. Telp. Penerima", dataKey: "sttRecipientPhone" },
    { header: "Total Koli", dataKey: "sttTotalPiece" },
    { header: "Total Berat", dataKey: "sttChargeableWeight" },
    {
      header: `${isDiscount ? "Total Tarif Sebelum Diskon" : "Total Tarif"}`,
      dataKey: "sttTotalAmount"
    }
  ];

  const amountColumns = [
    {
      header: `${isDiscount ? "Total Tarif Setelah Diskon" : "Total Tarif"}`,
      dataKey: "sttTotalAmountAfterDiscount"
    }
  ];
  doc.autoTable({
    body: dataList,
    columns: isDiscount ? [...mainColumns, ...amountColumns] : mainColumns,
    theme: "plain",
    startY: doc.internal.getCurrentPageInfo().pageNumber === 1 ? y : 0,
    margin: 7,
    headStyles: {
      font: "helvetica",
      fontStyle: "bold",
      fillColor: "#f5f6f7",
      textColor: "#1a1421",
      fontSize: 7,
      halign: "left",
      valign: "middle",
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 }
    },
    bodyStyles: {
      font: "helvetica",
      fontStyle: "normal",
      fillColor: "#fff",
      textColor: "#1a1421",
      fontSize: 7,
      halign: "left",
      valign: "middle",
      lineColor: "#9e9e9e",
      lineWidth: { top: 0.05, right: 0, bottom: 0.05, left: 0 },
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 }
    },
    columnStyles: {
      2: { cellWidth: 40 },
      8: { cellWidth: 30 },
      9: { cellWidth: 30 }
    },
    willDrawCell: (data: any) => {
      drawCellData(doc, data)
    }
  });
};

const InvoiceAttachment = async (params: {
  name: string;
  isDiscount: boolean;
  total: string;
  totalAfterDiscount: string;
  createdAt: string;
  createdBy: string;
  dataList: Array<any>;
  logo: string;
}) => {
  const doc: any = new JsPdf("l", "mm", "a4", true);
  let y = 30;
  doc.addImage(params.logo, "PNG", 7, 9, 40, 10, "logo", "FAST", 0);
  doc.setDrawColor("#9e9e9e");
  doc.line(7, 23, 288, 23);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Tanggal", 7, y);
  doc.text(":", 60, y);
  doc.text(params.createdAt, 64, y);

  if (params.name.toLowerCase() !== "internal") {
    y += 5;
    doc.text("Nama POS", 7, y);
    doc.text(":", 60, y);
    doc.setTextColor("#4d4d4d");
    doc.text(params.name, 64, y);
  }

  y += 5;
  doc.setTextColor("#4d4d4d");
  doc.text("Total Resi", 7, y);
  doc.text(":", 60, y);
  doc.text(String(params.dataList.length), 64, y);

  const totalColor = params.isDiscount ? "#9e9e9e" : "#B82025";
  y += 5;
  doc.setTextColor("#4d4d4d");
  doc.text("Total Tagihan", 7, y);
  doc.text(":", 60, y);
  doc.setTextColor(totalColor);
  doc.text(params.total, 64, y);

  if (params.isDiscount) {
    doc.setDrawColor("#9e9e9e");
    doc.setLineWidth(0.2);
    const totalWidth = doc.getTextWidth(params.total);
    doc.line(64 + totalWidth, y - 1, 64, y - 1);
    doc.setTextColor("#B82025");
    doc.text(params.totalAfterDiscount, 66 + totalWidth, y);
  }
  y += 5;

  table(doc, params.dataList, params.isDiscount, y);
  doc.setProperties({
    title: "Data Receipt"
  });

  return doc.output("blob");
};

export default InvoiceAttachment;
